import React, { useEffect, useRef, useMemo, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './Map.css';

const Map = () => {
  const mapRef = useRef(null); // Reference to the map container
  const mapInstanceRef = useRef(null); // Store the Leaflet map instance
  const placeName = "Camp Jakkalsfontein"; // Name of the place

  // Memoize the position array
  const position = useMemo(() => [-33.3398990, 19.1169840], []);

  // State to dynamically adjust the map's height
  const [mapHeight, setMapHeight] = useState('400px');

  // Function to update the height based on the window size
  const updateMapHeight = () => {
    if (window.innerWidth <= 480) {
      setMapHeight('300px'); // Smaller height for mobile devices
    } else if (window.innerWidth <= 768) {
      setMapHeight('350px'); // Medium height for tablets
    } else {
      setMapHeight('400px'); // Default height for larger screens
    }
  };

  useEffect(() => {
    // Set initial height
    updateMapHeight();

    // Add resize event listener
    window.addEventListener('resize', updateMapHeight);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('resize', updateMapHeight);
    };
  }, []);

  useEffect(() => {
    if (mapInstanceRef.current) return;

    mapInstanceRef.current = L.map(mapRef.current).setView(position, 15);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(mapInstanceRef.current);

    const customIcon = L.icon({
      iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
    });

    L.marker(position, { icon: customIcon })
      .addTo(mapInstanceRef.current)
      .bindPopup(`<strong>${placeName}</strong><br>Away from the busy city life!`)
      .openPopup();
  }, [position, placeName]);

  return (
    <div className="map-container">
      <div className="map-info"></div>
      <div
        ref={mapRef}
        className="map"
        style={{ height: mapHeight, width: '100%' }}
      ></div>
    </div>
  );
};

export default Map;
