import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Services from './components/Services/Services';
import Banner from './components/Banner/Banner';
import Footer from './components/Footer/Footer';
import Map from './components/Map/Map';

function App() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [contactSubject, setContactSubject] = useState('');


  const openContactModal = (subject = '') => {
    setContactSubject(subject);
    setIsContactModalOpen(true);
  };
  
  const closeContactModal = () => {
    setIsContactModalOpen(false);
    setContactSubject('');
  };

  return (
    <Router>
      <Banner openContactModal={openContactModal} />
      <main>
        <Routes>
          <Route exact path="/" element={<Home openContactModal={openContactModal} />} />
          <Route path="/about" element={<About />} />
          <Route path="/map" element={<Map />} />
          <Route path="/services" element={<Services />} />
        </Routes>
        <Contact isModalOpen={isContactModalOpen} closeModal={closeContactModal} subject={contactSubject} />
      </main>
      <Footer />
    </Router>

  );
}

export default App;
