import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { Alert, Snackbar } from '@mui/material';
import './Contact.css';


const Contact = ({ isModalOpen, closeModal, subject }) => {
  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    subject: subject || '', // Default to the passed subject or empty string
    from_date: '',
    to_date: '',
    message: '',
  });

  // Alert state
  const [alert, setAlert] = useState({
    open: false,
    type: 'success', // 'success' or 'error'
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    // If the subject is updated, set it in formData
    setFormData((prevData) => ({
      ...prevData,
      subject: subject || '', // Ensure it's updated when the modal is opened
    }));
  }, [subject]);

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        'service_h71phpu', // Service ID
        'template_ofyzv8m', // Template ID
        formData,
        '95Fhx-o0j3iqug2KP' // User ID
      )
      .then((response) => {
        setAlert({
          open: true,
          type: 'success',
          message: 'Email sent successfully!',
        });
        setFormData({
          user_name: '',
          user_email: '',
          subject: '',
          from_date: '',
          to_date: '',
          message: '',
        });
        setTimeout(closeModal, 1000);
      })
      .catch((error) => {
        setAlert({
          open: true,
          type: 'error',
          message: 'Failed to send email. Please try again.',
        });
        console.error('Failed to send email:', error);
      });
  };

  const handleCloseAlert = () => {
    setAlert((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-button" onClick={closeModal}>&times;</span>
            <form onSubmit={handleSubmit}>
              <div className="form-field">
                <label htmlFor="user_email">Email:</label>
                <input
                  type="email"
                  id="user_email"
                  name="user_email"
                  className="input"
                  value={formData.user_email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-field">
                <label htmlFor="subject">Subject:</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  className="input"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-field date-range">
                <div className="date-field">
                  <label htmlFor="from_date">From Date:</label>
                  <input
                    type="date"
                    id="from_date"
                    name="from_date"
                    className="input"
                    value={formData.from_date}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="date-field">
                  <label htmlFor="to_date">To Date:</label>
                  <input
                    type="date"
                    id="to_date"
                    name="to_date"
                    className="input"
                    value={formData.to_date}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="form-field">
                <label htmlFor="message">Message:</label>
                <textarea
                  id="message"
                  name="message"
                  rows="5"
                  className="textarea"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit" className="button">Send</button>
            </form>
          </div>
        </div>
      )}

      <Snackbar
        open={alert.open}
        autoHideDuration={4000}
        onClose={handleCloseAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.type}
          sx={{ width: '100%' }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Contact;
