import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Banner.css';
import logo from '../../assets/logo/newLogo.png';

const Banner = ({ openContactModal }) => {
  const location = useLocation();

  const isNotLandingPage = location.pathname !== '/';

  return (
    <div
      className={`banner-container ${isNotLandingPage ? 'transparent-background' : ''}`}
    >
      <div className="banner-icon-container">
        <img className="banner-icon" src={logo} alt="Logo" />
      </div>
      <div className="nav-links">
        <Link to="/" className="lavender-link">Home</Link>
        <Link to="/about" className="lavender-link">About</Link>
        <Link to="/services" className="lavender-link">Services</Link>
        <Link to="/map" className="lavender-link">Map</Link>
        <Link
          onClick={() => openContactModal(``)}
          
          className="lavender-link"
          style={{ cursor: 'pointer' }}
        >
          Contact
        </Link>
      </div>
    </div>
  );
};

export default Banner;
