import React, { useState } from 'react';
import './Home.css';
import image1 from '../../assets/pictures/booking pictures/fullfarm.jpg';
import image3 from '../../assets/pictures/booking pictures/tent.jpg';
import image2 from '../../assets/pictures/booking pictures/barnhouse.jpg';
import dropdown from '../../assets/icons/matIcons/drop.png'
// import OverviewPage from '../Overview/OverviewPage';
import OverviewPage from '../Overview/OverviewPage';
import Welcome from './WelcomeSection/Welcome';
import CarouselPage from './carousel/Carousel';

const importAll = (r) => r.keys().map(r);


const Home = ({ openContactModal }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [showMoreInfo, setShowMoreInfo] = useState(false); // State for toggling the div
  const [showOverview, setShowOverview] = useState(false);
  const [selectedCampData, setSelectedCampData] = useState(null);

  const campData = {
    "Camp 1": {
      title: "Entire Farm & Campsite",
      address: "Tulbagh Road, Jakkersfontein farm, Waterval AH, Tulbagh, Western Cape, South Africa",
      images: importAll(require.context('../../assets/pictures/booking pictures/EntireFarm', false, /\.(png|jpe?g|svg)$/)),
      description: `Nestled in the charming town of Tulbagh, The Entire Barnhouse at Camp Jakkalsfontein offers a delightful getaway complete with a private pool, a lovely patio, and stunning mountain views. This inviting property features a terrace, free private parking, and complimentary WiFi, making it a perfect retreat. Guests can enjoy the lush garden and spacious accommodations, which include four cozy bedrooms, six bathrooms, a fully equipped kitchen with a dining area and dishwasher, and a comfortable living room with a flat-screen TV. Whether you're dining al fresco in the outdoor area or cozying up by the fireplace on chilly evenings, this place has it all. For families, there's an indoor play area and outdoor play equipment to keep the little ones entertained. After a day of cycling, unwind by the outdoor fireplace and soak in the serene atmosphere. The Entire Barnhouse is conveniently located just 7.5 km from De Oude Kerk Museum Tulbagh and 33 km from Ceres Golf Club, with Cape Town International Airport only 110 km away.`,
    },
    "Camp 2": {
      title: "Entire Barnhouse",
      address: "Tulbagh Road, Jakkersfontein farm, Waterval AH, Tulbagh, Western Cape, South Africa",
      images: importAll(require.context('../../assets/pictures/booking pictures/Barnhouse', false, /\.(png|jpe?g|svg)$/)),
      description: `If you're looking for a cozy getaway, The Entire Barnhouse at Camp Jakkalsfontein in Tulbagh is the perfect spot! This charming accommodation boasts a private pool, a lovely patio, and stunning mountain views. You'll enjoy easy access to a terrace, free parking, and complimentary WiFi, plus a beautiful garden to explore. Inside, the spacious apartment features four bedrooms and six bathrooms, along with a fully equipped kitchen that includes a dining area and a dishwasher. The living room is perfect for relaxing, complete with a flat-screen TV. You can soak up the beautiful surroundings from the outdoor dining area or snuggle up by the fireplace when the weather gets chilly. For those seeking a bit more privacy, there's a private entrance as well. Families will appreciate the indoor play area and outdoor play equipment for the kids, and after a day of cycling, you can unwind by the outdoor fireplace. If you're interested in local attractions, De Oude Kerk Museum Tulbagh is just 7.5 km away, and Ceres Golf Club is 33 km from the barnhouse. Plus, Cape Town International Airport is a convenient 110 km away, making it easy to plan your visit!`,
    },
    "Camp 3": {
      title: "Luxury Pre-Pitched Tent",
      address: "Tulbagh Road, Jakkersfontein farm, Waterval AH, Tulbagh, Western Cape, South Africa",
      images: importAll(require.context('../../assets/pictures/booking pictures/TentOnly', false, /\.(png|jpe?g|svg)$/)),
      description: `If you're looking for a cozy getaway, check out the Luxury Pre-Pitched 2-4 Person Tent in Tulbagh, just 7.5 km away from the De Oude Kerk Museum. This charming spot offers stunning mountain views, complimentary WiFi, and free parking, making it a perfect retreat. Located 48 km from the Porterville golf course, the tent is surrounded by a lovely garden and a terrace, plus there's a year-round outdoor pool for those sunny days. You can enjoy meals in the outdoor dining area, and the tent also boasts a private entrance for a bit of extra seclusion. And if you're flying in, Cape Town International Airport is a convenient 110 km away.`,
    },
  };

  const handleReserveClick = (camp) => {
    setSelectedCampData(campData[camp]); // Set the selected camp data
    setShowOverview(true); // Show the OverviewPage    
  };

  if (showOverview) {
    return (
      <OverviewPage
        onBack={() => setShowOverview(false)}
        data={selectedCampData} // Pass selected camp data as a prop
        openContactModal={(subject) => openContactModal(subject)} // Make sure the subject is passed correctly
      />
    );
  }

  const openFullscreen = (image) => {
    setSelectedImage(image);
  };

  const closeFullscreen = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <div className="home-container">
        <div className="styled-home-content">
            <h3 className="home-text">
              <span className="rest">
                Camp Jakkalsfontein offers a unique mix of glamorous camping and a charming farm experience. Whether you're into a more traditional camping vibe or the luxury of glamping, you can also choose to stay in a beautiful barnhouse. We can host couples and groups of up to 20, making it ideal for special occasions, team-building events, or family get-togethers. Enjoy breathtaking views of the Witzenberg, Winterhoek, and Obiqua Mountains while engaging in activities like hiking, mountain biking, swimming, and picnicking, or just kick back and soak in the peaceful atmosphere. Plus, you’ll have access to unlimited Wi-Fi, streaming services, and an awesome cinema setup.
              </span>
            </h3>

            <CarouselPage />

            <div className="contact-button"
              onClick={(e) => {
                e.preventDefault();
                setShowMoreInfo(!showMoreInfo); // Toggle the new section
              }}>
              <span>{showMoreInfo ? 'Close' : 'Book Now'}</span>
              <img src={dropdown} alt="dropdown icon" className={`dropdown-icon ${showMoreInfo ? 'rotated' : ''}`} />
            </div>
          
        </div>
      </div>

      {showMoreInfo && (
        <div className="info">
          <div className="one">
            <img src={image1} alt="entireFarm"
              onClick={() => openFullscreen(image1)}
              style={{ cursor: 'pointer' }}
            />
            <div className="summary">
              Book the entire farm & campsite
            </div>
            <button
              className="reserve-button"
              onClick={() => handleReserveClick('Camp 1')}>
              Book
            </button>
          </div>

          <div className="two">
            <img src={image2} alt="barn"
              onClick={() => openFullscreen(image2)}
              style={{ cursor: 'pointer' }}
            />
            <div className="summary">
              Book the entire barnhouse
            </div>
            <button
              className="reserve-button"
              onClick={() => handleReserveClick('Camp 2')}>
              Book
            </button>
          </div>

          <div className="three">
            <img src={image3} alt="tent"
              onClick={() => openFullscreen(image3)}
              style={{ cursor: 'pointer' }}
            />
            <div className="summary">
              Book a luxury pre-pitched 2-4 person tent
            </div>
            <button
              className="reserve-button"
              onClick={() => handleReserveClick('Camp 3')}>
              Book
            </button>
          </div>
        </div>
      )}

      <Welcome />

      {selectedImage && (
        <div className="fullscreen-overlay" onClick={closeFullscreen}>
          <img src={selectedImage} alt="Fullscreen view" className="fullscreen-image" />
        </div>
      )}
    </>
  );
};

export default Home;


