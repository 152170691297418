import image4 from '../../../assets/pictures/veld.jpg';
import image5 from '../../../assets/pictures/view.jpg';
import image6 from '../../../assets/pictures/pinkflowers.jpg';
import React from 'react';
import './Welcome.css';

const Welcome = () => {
    return (
        <div className='rest-home'>
            <div className='camp-jk-summary'>
                <span className='heading'>Welcome to Camp JakkalsFontein</span>
                <span className='body'>
                    Discover the splendor of the natural world at Camp Jakkalsfontein! Grab your spot for an adventure and dive into the great outdoors, engaging with nature in an unparalleled way.. Tulbagh is situated at the northern edge of the picturesque Tulbagh Valley, encircled by magnificent mountain ranges.
                </span>
                <p className="body">
                    Tulbagh is nestled at the northern tip of the stunning Tulbagh Valley, surrounded by breathtaking mountain ranges.
                </p>
            </div>
            <div className='more-camp-info'>
                <div className='camp-info-one'>
                    <img src={image4} alt='veld' />
                    <div className='image-summary'>To the East, the Witzenberg Mountains greet each day with the first light of dawn, where the sun rises to illuminate the valley.</div>
                </div>
                <div className='camp-info-two'>
                    <img src={image5} alt='view' />
                    <div className='image-summary'>At the northern end rises the magnificent Winterhoek, dominating the valley with its formidable stature.</div>
                </div>
                <div className='camp-info-three'>
                    <img src={image6} alt='pinkflowers' />
                    <div className='image-summary'>To the west, the Obiqua Mountains present breathtaking sunsets that illuminate the sky with shades of gold and crimson.</div>
                </div>
            </div>
        </div>
    )
}

export default Welcome;
