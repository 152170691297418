import React, { useState } from 'react';
import './OverviewPage.css';
import { Carousel } from 'react-responsive-carousel';

const OverviewPage = ({ onBack, data, openContactModal }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openFullscreen = (image) => {
    setSelectedImage(image);
  };

  const closeFullscreen = () => {
    setSelectedImage(null);
  };

  return (
    <div className="overview-container">
      <button className="back-button" onClick={onBack}>
        &larr; Back
      </button>
      <div className="overview-header">
        <h1>{data.title}</h1>
        <address>{data.address}</address>
      </div>
      <div className="overview-images">
        <Carousel
          showThumbs={false}
          autoPlay
          infiniteLoop
          centerMode
          centerSlidePercentage={33.33}
          slideInnerMargin={10}
          showStatus={false}
          showIndicators={false}
          showArrows={true}
        >
          {data.images.map((image, index) => (
            <div
              key={index}
              className="carousel-slide"
              onClick={() => openFullscreen(image)}
              style={{ cursor: 'pointer' }}
            >
              <img src={image} alt={`img ${index + 1}`} />
            </div>
          ))}
        </Carousel>
      </div>
      {selectedImage && (
        <div className="fullscreen-overlay" onClick={closeFullscreen}>
          <img src={selectedImage} alt="Fullscreen view" className="fullscreen-image" />
        </div>
      )}
      <div className="overview-description">
        <p>{data.description}</p>
      </div>
      <button
        className="book-button"
        onClick={() => openContactModal(`Booking Inquiry: ${data.title}`)}
      >
        Inquire Now
      </button>
    </div>
  );
};

export default OverviewPage;
