import React, { useEffect, useState } from 'react';
import './Services.css';

const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../../assets/icons', false, /\.(png|jpe?g|svg)$/));

const Services = () => {
  const descriptions = [
    "Quad Biking approved",
    "Braai Area available",
    "Tent available for hire",
    "Cleaning available",
    "Cycling Trails",
    "Hiking Trails",
    "Projector with Netlfix available",
    "Ready to use Kitchen",
    "Pet Friendly",
    "Playground for kids",
    "Weights available",
    "Kiddies and Adult Swimming pool",
    "Waterfall less than 5km walk away",
    "Running Trails",
    "More To Come",
  ];

  const [flippedIndex, setFlippedIndex] = useState(null);

  useEffect(() => {
    // Automatically flip the first image on page load
    const timer = setTimeout(() => setFlippedIndex(0), 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="services-background">
      <div className="Services-header">
        <h1>
          Services
        </h1>
      </div>
        <div className="logo-container">
          {images.map((image, index) => (
            <div
              key={index}
              className={`logo-wrapper ${flippedIndex === index ? 'flipped' : ''}`}
              onMouseEnter={() => setFlippedIndex(index)}
              onMouseLeave={() => setFlippedIndex(null)}
            >
              <div className="logo-front">
                <img src={image} alt={`Image-${index + 1}`} className="logos" />
              </div>
              <div className="logo-back">
                <span className="logo-text">{descriptions[index]}</span>
              </div>
            </div>
          ))}
        </div>

    </div>
  );
};

export default Services;
