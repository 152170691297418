import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Carousel.css';

const importAll = (r) => r.keys().map(r);
const images = importAll(require.context('../../../assets/pictures', false, /\.(png|jpe?g|svg)$/));

const CarouselPage = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const openFullscreen = (image) => {
        setSelectedImage(image);
      };
    
      const closeFullscreen = () => {
        setSelectedImage(null);
      };

    return(
        <div className='carousel'>
        <div className="carousel-wrapper">
          <Carousel
                    showThumbs={false}
                    autoPlay
                    infiniteLoop
                    transitionTime={100} /* Adjust transition speed */
                    centerMode={!isMobile} // Disable centerMode on mobile
                    centerSlidePercentage={isMobile ? 100 : 33.33} // Show one image on mobile
                    showStatus={false}
                    showIndicators={false}
          >
            {images.map((image, index) => (
              <div key={index} className="carousel-slide" onClick={() => openFullscreen(image)}
                style={{ cursor: 'pointer' }}>
                <img src={image} alt={`img ${index + 1}`} />
              </div>
            ))}
          </Carousel>
        </div>
        {selectedImage && (
        <div className="fullscreen-overlay" onClick={closeFullscreen}>
          <img src={selectedImage} alt="Fullscreen view" className="fullscreen-image" />
        </div>
      )}
      </div>
    )
}

export default CarouselPage;



