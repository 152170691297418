import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="styled-about-content">
      <span className="about-text">
        <p className="sentence-red-first">
          Welcome to our stunning 3.8-hectare farm and campsite, where you and your group can relish in total privacy throughout the entire property, featuring a fully furnished barn and a cozy 3-bedroom cottage.
        </p>
        <ul className="list-items">
          <li>
            We can accommodate up to 7-8 couples in private rooms, plus there are extra sleeper couches and bunk beds available to ensure everyone has a comfy place to rest.
            </li>
          <li>
            Your group will have exclusive access to all facilities, including a beautifully renovated barn ideal for events and gatherings. The property is perfect for weddings, meetings, or any function for 6 to 12 guests.
          </li>
          <li>
          You’ll have exclusive access to all the amenities, including a beautifully renovated barn that’s perfect for events and gatherings.
          </li>
          <li>
            Whether it’s a wedding, a meeting, or any other function for 6 to 12 guests, this property is ideal. For those who love the outdoors, we offer fantastic camping spots with well-kept ablution facilities, including hot and cold showers.
          </li>
          <li>
            Enjoy both indoor and outdoor braai (barbecue) areas, a spacious swimming pool, and a fully equipped kitchen that can cater to up to 12 people. Experience the perfect mix of rustic charm and luxury with exclusive access to indoor spaces, the swimming pool, entertainment area, and camping grounds. If you’re keen on setting up your own camp or glamping site, we’re more than happy to assist.
          </li>
          <li>
            The Barnhouse and Cottage at Camp Jakkalsfontein are part of our Entire Farm offering, featuring queen-sized beds that are fully outfitted, along with bunk beds that come with mattresses and fitted sheets.
          </li>
        </ul>
      </span>
    </div>
  );
};

export default About;
